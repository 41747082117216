
export interface PostType {
    cursor: number;
    id: string;
    likes: number;
    media: string;
    personId: string;
    personName: string;
    time: string;
    url: string;
    slug?: string;
    aliases?: string[];
  }

  export interface InfData {
    data: PostType[]
  }
  
  export const mockPosts: PostType[] = [
    {
      cursor: 1638486493000,
      id: "1466544763327369218",
      likes: 0,
      media: "twitter.com",
      personId: "jennifer-kate-hudson-09.12.1981",
      personName: "Jennifer Kate Hudson",
      time: "Thu Dec 02 23:08:13 +0000 2021",
      url: "https://twitter.com/iamjhud/status/1466544763327369218",
      aliases: []
    },
    {
      cursor: 1638478823000,
      id: "1466512594034962432",
      likes: 0,
      media: "twitter.com",
      personId: "dwayne-douglas-johnson-05.02.1972",
      personName: "Dwayne Douglas Johnson",
      time: "Thu Dec 02 21:00:23 +0000 2021",
      url: "https://twitter.com/TheRock/status/1466512594034962432",
      aliases: []
    },
  ];
  
  export const resToPosts = (res: any[]): PostType[] => {
    return res.map((r) => {
      return {
        cursor: r.cursor,
        id: r.id,
        likes: r.likes,
        media: r.media,
        personId: r.personid,
        personName: r.personname,
        time: r.time,
        url: r.url,
        aliases: r.aliases,
        embed: r.embed ?? ""
      };
    });
  };

  export const jsonToInfData = (res: any): InfData => {
    const data = resToPosts(res.data)
    return {
      data: data,
    }
  };