import { TwitterTweetEmbed } from "react-twitter-embed";
import YoutubeEmbed from "./YoutubeEmbed";
import TwitchEmbed from "./TwitchEmbed";
import RedditEmbed from "./RedditEmbed";
import MixerEmbed from "./MixerEmbed";
import styled from "styled-components";
import ReactLoading from "react-loading";
import { colors } from "../../utils/colors";
import TikTokEmbed from "./TikTokEmbed";

export const Embed = ({ post }) => {
  switch (post.media) {
    case "twitter.com":
      let tweetId = post.url.toString().split("/").pop() || "";
      return (
        <TwitterTweetEmbed
          tweetId={tweetId}
          options={{
            width: Math.min(520, window.screen.width),
            align: "center",
          }}
          placeholder={
            <CenterLoading>
              <ReactLoading
                type={"cylon"}
                color={colors.contrastColor}
                width={40}
              />
            </CenterLoading>
          }
        />
      );
    case "reddit.com":
      return (
        <Center>
          <RedditEmbed html={post.embed} />
        </Center>
      );
    case "youtube.com":
      return (
        <Center>
          {" "}
          <YoutubeEmbed url={post.url} />
        </Center>
      );
    case "tiktok.com":
      return (
        <Center>
          <TikTokEmbed html={post.embed} />
        </Center>
      );
    case "twitch.tv":
      return (
        <Center>
          <TwitchEmbed videoId={post.id} />
        </Center>
      );
    case "mixer.com":
      return (
        <Center>
          <MixerEmbed url={post.url} />
        </Center>
      );
    default:
      return <div></div>;
  }
};

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const CenterLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 300px;
`;
