import React, {useEffect} from "react";

const TikTokEmbed = ({ html }) => {
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  
  return (
    <div className="tiktokEmbed" dangerouslySetInnerHTML={{__html: html}}></div>
  );
};

export default TikTokEmbed;
