import React from "react";
import useAutoResize from "./useAutoResize";

const TwitchEmbed = ({ videoId }) => {
  const [width, height] = useAutoResize();
  return (
    <iframe
      title={videoId}
      src={"https://player.twitch.tv/?autoplay=false&video=" + videoId}
      height={height}
      width={width}
      frameBorder="0"
      scrolling="false"
      allowFullScreen={true}
    ></iframe>
  );
};

export default TwitchEmbed;
