import LinkIcon from "./icons/LinkIcon";
import MinusIcon from "./icons/MinusIcon";
import PlusIcon from "./icons/PlusIcon";
import SearchIcon from "./icons/SearchIcon";
import TrashIcon from "./icons/TrashIcon";

export type AvailableIcons = "link" | "search" | "plus" | "trash" | "minus";

type Props = {
  name: AvailableIcons;
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

const Icon = ({
  name,
  className,
  width,
  height,
  color,
}: Props): JSX.Element => {
  switch (name) {
    case "link":
      return <LinkIcon className={className} />;
    case "search":
      return <SearchIcon className={className} />;
    case "plus":
      return (
        <PlusIcon
          className={className}
          width={width}
          height={height}
          color={color}
        />
      );
    case "minus":
      return <MinusIcon className={className} width={width} height={height} />;
    case "trash":
      return <TrashIcon className={className} width={width} height={height} />;
    default:
      return <svg></svg>;
  }
};

export default Icon;
