import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import Icon from "../../utils/Icon";
import { mobileWidth } from "../../utils/styles";
import Logo from "./Logo";

const HeaderContainer = styled.div`
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${mobileWidth}) {
    background-color: ${colors.backgroundColor};
    padding-left: 4px;
    padding-right: 1em;
    padding-bottom: 1em;
  }
`;

const HeaderContainerContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
`;

const Header = () => {
  return (
    <HeaderContainerContainer>
      <HeaderContainer>
        <Logo />
        <PlusButton to={"/add-person"}>
          <div>suggest new people</div>
          <Icon name={"plus"} height={32} width={32} />
        </PlusButton>
      </HeaderContainer>
    </HeaderContainerContainer>
  );
};
export default Header;

const PlusButton = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;

  text-decoration: none;
  color: ${colors.white};
  :hover {
    color: ${colors.contrastColor};
    text-decoration: underline;
  }
`;
