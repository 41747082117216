import { useState } from "react";
import Since from "react-since";
import styled from "styled-components";
import { Embed } from "./Embed";
import EmojiButton from "../util/EmojiButton";
import { colors } from "../../utils/colors";
import { useMutation } from "react-query";
import { Urls } from "../chamber/Urls";
import axios from "axios";
import { useNavigate } from "react-router";
import { mobileWidth } from "../../utils/styles";

const Post = ({ post, addLike }) => {
  const navigate = useNavigate();
  const [likes, setLikes] = useState(post.likes);

  const incrementLikes = () => {
    addLike(post)
    setLikes(likes+1)
  }

  const getPersonChamberMutation = useMutation(
    (personId) => axios.post(Urls.api.singleton(), { personid: personId }),
    {
      onSuccess: ({ data }) => {
        const url = Urls.www.getChamber(data.slug);
        navigate(url);
      },
    }
  );


  let name = post.personName;
  if (post.aliases !== undefined && post.aliases !== null && post.aliases.length > 0){
    name = post.aliases[0]
  }


  return (
    <PostContainer>
      <Meta post={post} name={name} onClick={getPersonChamberMutation.mutate} />
      <EmbedContainer>
        <Embed post={post} />
      </EmbedContainer>
      <Bottombox>
        <EmojiButton
          value={likes.toString()}
          emoji={"🔥"}
          onClick={() => incrementLikes()}
        />
      </Bottombox>
    </PostContainer>
  );
};

const PostContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  @media (max-width: ${mobileWidth}) {
    padding: 0;
  }
`;

const Timestamp = styled.span`
  font-weight: bold;
`;


const Meta = ({ post, name, onClick }) => (
  <MetaContainer>
    <div>
      <Name
      onClick={() => {
        onClick(post.personId);
      }}
      >{name}</Name> on {post.media}
    </div>
    <Timestamp>
      {post.time === undefined ? "" : <Since date={new Date(post.time)} />}
    </Timestamp>
  </MetaContainer>
);

const MetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.75em;
  color: grey;
  padding: 8px 0;
`;

const Name = styled.span`
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: ${colors.contrastColor};
    text-decoration: underline;
  }
`;

const Bottombox = styled.div`
  height: 80px;
  display: flex;
  justify-content: flex-end;
  > * + * {
    margin-left: 8px;
  }
`;

const EmbedContainer = styled.div`
  min-height: 200px;
  @media (max-width: ${mobileWidth}) {
    min-height: 128px;
  }
`;

export default Post;
