import { ChangeEvent } from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import Icon from "../../utils/Icon";

const MediaInput = ({
  onTextChange,
  onClick,
  value,
  readOnly,
}: {
  onTextChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  value: string;

  readOnly: boolean;
}) => {
  return (
    <Row>
      <Input
        onChange={(event) => {
          onTextChange(event);
        }}
        placeholder={`Eg: "twitter.com/jlo"`}
        value={value}
        readOnly={readOnly}
      />
      <IconButton
        onClick={() => {
          onClick();
        }}
      >
        <Icon name={readOnly ? "minus" : "plus"} />
      </IconButton>
    </Row>
  );
};

export default MediaInput;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
`;

const Input = styled.input<{ readOnly: boolean }>`
  box-sizing: border-box;
  width: calc(100% - 48px);
  border: solid 1px
    ${(props) => (props.readOnly ? colors.lightGreyIsh : colors.contrastColor)};
  background-color: transparent;
  color: ${colors.white};
  padding: 8px 16px;
  border-radius: 48px;
  margin-left: auto;
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "text")};

  :focus {
    outline: none;
  }
`;

const IconButton = styled.button`
  background-color: ${colors.backgroundColor};
  color: ${colors.contrastColor};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 32px;

  :hover {
    background-color: ${colors.buttonGrey};
  }
  :active {
    background-color: ${colors.contrastColor};
    color: ${colors.backgroundColor};
  }
  margin-left: 8px;
  width: 40px;
`;
