import { useState } from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import Icon from "../../utils/Icon";
import { NestedChamberType } from "../../utils/interfaces";
import OutsideClickHandler from "react-outside-click-handler";

const AddNestedChamberComponent = ({
  onSubmit,
}: {
  onSubmit: (chamber: NestedChamberType) => void;
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [showInput, setShowInput] = useState<boolean>(false);

  const validSlug = (): boolean => {
    const slug = inputValue.split("/").pop() || "";
    return slug.length === 32;
  };

  return (
    <Wrapper>
      {showInput ? (
        <Form onSubmit={(e) => e.preventDefault()}>
          <InputWrapper>
            <Icon name={"link"} />
            <OutsideClickHandler
              onOutsideClick={() => {
                if (!validSlug()) {
                  setShowInput(false);
                }
              }}
            >
              <SlugInput
                valid={validSlug()}
                placeholder={"Paste link to chamber"}
                value={inputValue}
                onChange={(event) => {
                  setInputValue(event.target.value);
                }}
              />
            </OutsideClickHandler>
          </InputWrapper>
          {validSlug() && (
            <Button
              disabled={inputValue.trim() === ""}
              onClick={() => {
                if (validSlug()) {
                  onSubmit({ nested_slug: inputValue.split("/").pop() || "" });
                  setInputValue("");
                }
                setShowInput(false);
              }}
            >
              <Icon name={"plus"} width={32} height={32} />
            </Button>
          )}
        </Form>
      ) : (
        <Row
          onClick={() => {
            setShowInput(true);
          }}
        >
          <Icon
            name={"plus"}
            width={32}
            height={32}
            color={colors.contrastColor}
          />
          <div>add new chamber link</div>
        </Row>
      )}
    </Wrapper>
  );
};

export default AddNestedChamberComponent;

const Wrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Button = styled.button`
  margin: 0;
  padding: 0;
  height: 32px;
  width: 32px;
  border-radius: 48px;
  background-color: ${colors.contrastColor};
  color: ${colors.greyIsh};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div`
  margin: 0;
  padding-left: 8px;
  width: calc(100% - 1em - 40px);
  height: 32px;
  margin-right: 1em;
  border-radius: 48px;
  border: 1px solid ${colors.contrastColor};
  display: flex;
  align-items: center;
  color: ${colors.contrastColor};
`;

const SlugInput = styled.input<{ valid: boolean }>`
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 32px;
  color: ${(props) => (props.valid ? colors.contrastColor : colors.white)};
  padding-left: 8px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    color: ${colors.contrastColor};
    text-decoration: underline;
  }

  > * + * {
    margin-left: 8px;
  }
`;
