import { useEffect, useState } from "react";
import styled from "styled-components";
import Post from "../post/Post";
import InfiniteScroll from "react-infinite-scroll-component";
import { colors } from "../../utils/colors";
import { Urls } from "./Urls";
import { jsonToInfData, PostType } from "../../utils/postType";
import { useInfiniteQuery, useMutation } from "react-query";
import axios from "axios";
import ReactLoading from "react-loading";

const ChamberInfiniteFeed = ({
  slug,
  subs,
}: {
  slug?: string;
  subs: string[];
}) => {
  const [posts, setPosts] = useState<PostType[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [currentSubs, setCurrentSubs] = useState<string[]>([]);

  const maxPerPage = subs.length * 5;
  let lastRefetch = 0;

  const addLikeMutation = useMutation(
    (data: any) => {
      return axios.post(Urls.api.addLike(), data);
    },
    { onError: (e) => console.log(e) }
  );

  const addLike = (post: PostType, likes: number) => {
    let data;
    data = {
      cursor: post.cursor,
      media: post.media,
      mediaId: post.id ? post.id : "",
      personId: post.personId,
      personName: post.personName,
      time: post.time,
      url: post.url,
      slug: slug,
      aliases: post.aliases ? post.aliases : [],
    };
    addLikeMutation.mutate(data);
  };

  const fetchInfinite = async ({ pageParam = 0 }) => {
    const controller = new AbortController();
    const signal = controller.signal;
    let response = fetch(Urls.api.getNextPostsForChamber(slug, pageParam), {
      method: "get",
      signal,
    });

    let data = await (await response).json();
    if (signal.aborted) {
      return jsonToInfData([]);
    }

    if (data.data.length === 0) {
      const newPageParam = lastRefetch + maxPerPage;
      response = fetch(Urls.api.getNextPostsForChamber(slug, newPageParam));

      data = await (await response).json();

      if (signal.aborted) {
        return jsonToInfData([]);
      }

      setOffset(newPageParam);
      lastRefetch = newPageParam;
    }

    const converted = jsonToInfData({ data: data.data });
    return converted;
  };

  const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    "urls",
    fetchInfinite,
    {}
  );

  useEffect(() => {
    if (subs !== currentSubs) {
      setCurrentSubs(subs);
      setPosts([]);
      refetch();
    }
    // eslint-disable-next-line
  }, [subs]);

  useEffect(() => {
    setPosts([]);
    refetch();
  }, [slug, refetch]);

  useEffect(() => {
    const tempData = data?.pages.map((value) => value.data).flat();
    if (tempData) {
      //const sorted = tempData.sort((a, b) => b.cursor - a.cursor);
      setPosts(tempData);
    }
  }, [data]);

  const fetchMore = () => {
    const newOffset = offset + 5;
    setOffset(newOffset);
    fetchNextPage({ pageParam: newOffset });
  };

  return (
    <Wrapper>
      <InfiniteScroll
        next={fetchMore}
        dataLength={posts.length}
        hasMore={hasNextPage ?? true}
        loader={
          <Loading>
            <ReactLoading
              type={"spokes"}
              color={colors.contrastColor}
              width={40}
            />
          </Loading>
        }
      >
        {posts.map((post, i) => {
          return <Post key={i} post={post} addLike={addLike} />;
        })}
      </InfiniteScroll>
    </Wrapper>
  );
};

export default ChamberInfiniteFeed;

const Wrapper = styled.div`
  box-sizing: inherit;
  font-family: inherit;
  width: 100%;
`;

const Loading = styled.h4`
  color: ${colors.contrastColor};
  width: 100%;
  display: flex;
  justify-content: center;
`;
