import axios from "axios";
import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import { mobileWidth } from "../../utils/styles";
import { Urls } from "../chamber/Urls";
import MediaInput from "./MediaInput";
import PersonInput from "./PersonInput";

export interface PersonFormState {
  name: string;
  personId: string;
  aliases: string;
  dob: string;
  media: string[];
}
const emptyForm: PersonFormState = {
  name: "",
  personId: "",
  dob: "",
  aliases: "",
  media: [],
};

const AddPerson = () => {
  const [formState, setFormState] = useState<PersonFormState>(emptyForm);
  const [errorText, setErrorText] = useState<string>("");
  const [submitPressed, setSubmitPressed] = useState<boolean>(false);

  const [mediaInput, setMediaInput] = useState<string>("");
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const validateForm = () => {
    if (formState.name === "" && formState.aliases === "") {
      setErrorText("Name or alias is needed.");
      return false;
    }
    if (formState.media.length === 0) {
      setErrorText("At least one social media username is neded.");
      return false;
    } else {
      for (let index = 0; index < formState.media.length; index++) {
        if (formState.media[index] === "") {
          setErrorText("Social media link cannot be empty.");
          return false;
        }
      }
    }
    setErrorText("");
    return true;
  };

  const getId = (dob: String) => {
    if (formState.name !== "") {
      return formState.name.replace(" ", "-").toLowerCase() + "-" + dob;
    } else {
      return (
        formState.aliases.split(",")[0].replace(" ", "-").toLowerCase() +
        "-" +
        dob
      );
    }
  };

  const cleanUrl = (url: String) => {
    let clean: string = url.split("://").pop()!;
    clean = clean.split("www.").pop()!;
    clean = clean.split("?")[0];
    return clean;
  };

  const formatName = () => {
    const split = formState.name.split(" ");
    const last = split.pop();
    let name = last + ", ";

    split.forEach((n) => {
      name += n + " ";
    });
    return name.trim();
  };

  const onSubmit = () => {
    setSubmitPressed(true);

    if (!validateForm()) {
      return;
    }
    const dob = formState.dob !== "" ? formState.dob : "00.00.0000";
    const id = getId(dob);

    const cleanedMedia = formState.media.map((m) => cleanUrl(m));

    const sortedMedia = cleanedMedia.sort((a, b) => 0 - (a < b ? 1 : -1));

    const person: PersonFormState = {
      name: formatName(),
      personId: id,
      dob: dob,
      aliases: formState.aliases,
      media: sortedMedia,
    };

    axios.post(Urls.py.suggestion(), person).then((res) => {
      if (res.status === 201) {
        clearForm();
        setShowSuccess(true);
      }
    });
  };

  const clearForm = () => {
    setErrorText("");
    setFormState(emptyForm);
  };

  const addMedia = (newMedia: string) => {
    setFormState((prev) => ({
      ...prev,
      media: [...prev.media, newMedia],
    }));
    validateForm();
  };

  const removeMedia = (index: number) => {
    const filtered = formState.media.filter(
      (m) => formState.media.indexOf(m) !== index
    );
    setFormState((prev) => ({
      ...prev,
      media: filtered,
    }));
    validateForm();
  };

  if (showSuccess) {
    return (
      <Wrapper>
        <Body>
          <Title>
            Success! A moderator will review your suggestion shortly.
          </Title>
          <CenterRow>
            <SubmitButton onClick={() => setShowSuccess(false)}>
              Ok
            </SubmitButton>
          </CenterRow>
        </Body>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Body>
          <Title>Missing someone? Suggest a new person to ECHO:</Title>
          <Form onSubmit={(event) => event.preventDefault()}>
            <PersonInput
              title={"Name"}
              placeholder={"Enter name"}
              value={formState.name}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFormState((prev) => ({ ...prev, name: event.target.value }));
                validateForm();
              }}
            />
            <PersonInput
              title={"Birthdate"}
              placeholder={"MM.DD.YYYY"}
              value={formState.dob}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFormState((prev) => ({ ...prev, dob: event.target.value }));
                validateForm();
              }}
            />
            <PersonInput
              title={"Aliases"}
              placeholder={"Add aliases (if any), separated by commas"}
              value={formState.aliases}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFormState((prev) => ({
                  ...prev,
                  aliases: event.target.value,
                }));
                validateForm();
              }}
            />
            <MediaHeader>Social media links</MediaHeader>
            <MediaInput
              onTextChange={(event) => {
                setMediaInput(event.target.value);

                validateForm();
              }}
              onClick={() => {
                addMedia(mediaInput);
                setMediaInput("");
              }}
              readOnly={false}
              value={mediaInput}
            />

            {formState.media.map((media, index) => {
              return (
                <MediaInput
                  key={media + index.toString()}
                  onTextChange={(event) => {
                    formState.media[index] = event.target.value;

                    setFormState((prev) => ({
                      ...prev,
                    }));

                    validateForm();
                  }}
                  onClick={() => {
                    removeMedia(index);
                  }}
                  readOnly={true}
                  value={formState.media[index]}
                />
              );
            })}

            <ButtonRow>
              {submitPressed && <ErrorText>{errorText}</ErrorText>}
              <ClearButton onClick={() => clearForm()}>clear</ClearButton>
              <SubmitButton onClick={() => onSubmit()}>Submit</SubmitButton>
            </ButtonRow>
          </Form>
        </Body>
      </Wrapper>
    );
  }
};

export default AddPerson;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 4em;
`;

const Body = styled.div`
  width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: ${mobileWidth}) {
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }
`;

const Title = styled.h2`
  margin-bottom: 40px;
`;

const MediaHeader = styled.h4`
  margin-top: 48px;
  margin-bottom: 16px;
`;

const Form = styled.form`
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;

  > * + * {
    margin-left: 16px;
  }
`;

const SubmitButton = styled.button`
  background-color: ${colors.contrastColor};
  color: ${colors.backgroundColor};
  border: 1px solid ${colors.contrastColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  border-radius: 8px;
  height: 40px;

  :hover {
    color: ${colors.greyIsh};
    text-decoration: underline;
  }
  :active {
    background-color: ${colors.backgroundColor};
    color: ${colors.contrastColor};
  }
`;

const ClearButton = styled.button`
  background-color: transparent;
  color: ${colors.lightGreyIsh};
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  height: 40px;

  :hover {
    color: ${colors.white};
    text-decoration: underline;
  }
  :active {
    background-color: ${colors.buttonGrey};
    color: ${colors.contrastColor};
  }
`;

const ErrorText = styled.p`
  color: ${colors.error};
  margin: 0;
`;

const CenterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
