import React from "react";
import useAutoResize from "./useAutoResize";

const MixerEmbed = ({ url }) => {
  const [width, height] = useAutoResize();

  /* see https://dev.mixer.com/guides/embeds/introduction */
  return (
    <iframe
      title={url}
      src={url + "?&muted=true&disableLinks=true"}
      height={height}
      width={width}
      frameBorder="0"
      scrolling="false"
      allowFullScreen={true}
    ></iframe>
  );
};

export default MixerEmbed;
