
export enum Environment {
    Localhost,
    Test,
    Production,
    Snap0,
    Snap1,
}

export function resolveEnvironment(): Environment {
    let hostname;
    let env: Environment;
    if (typeof window !== "undefined") {
      hostname = window.location.hostname;
    } else {
      hostname = process.env.CURRENT_ENV;
    }
  
    if (hostname?.includes("snap0") || process?.env?.CURRENT_ENV === "snap0") {
      env = Environment.Snap0;
    } else if (
      hostname?.includes("snap1") ||
      process?.env?.CURRENT_ENV === "snap1"
    ) {
      env = Environment.Snap1;
    } else if (
      hostname?.endsWith(".test.iterate.no") ||
      process?.env?.CURRENT_ENV === "test"
    ) {
      env = Environment.Test;
    } else if (hostname === "localhost") {
      env = Environment.Localhost;
    } else {
      env = Environment.Production;
    }
  
    return env;
  }


  export function getWsPath(): string | undefined{
    const env: Environment = resolveEnvironment();
    switch (env) {
      case Environment.Localhost:
        return "ws://localhost:8888";
      case Environment.Test:
        return "wss://somedir-clj.somedir.test.iterate.no";
      case Environment.Snap0:
        return "wss://snap0.somedir.iterate.iterapp.no";
      case Environment.Snap1:
        return "wss://snap1.somedir.iterate.iterapp.no";
      case Environment.Production:
        return "wss://echotheapp.com";
      default:
        return;

  }
}

  export function getBasePath(): string | undefined {
    const env: Environment = resolveEnvironment();
    switch (env) {
      case Environment.Localhost:
        return "http://localhost:8888";
      case Environment.Test:
        return "https://somedir-clj.somedir.test.iterate.no";
      case Environment.Snap0:
        return "https://snap0.somedir.iterate.iterapp.no";
      case Environment.Snap1:
        return "https://snap1.somedir.iterate.iterapp.no";
      case Environment.Production:
        return "https://echotheapp.com";
      default:
        return;
    }
  }

export function getPyPath():string | undefined {
  const env: Environment = resolveEnvironment();
    switch (env) {
      case Environment.Localhost:
        return "http://localhost:5555";
      case Environment.Test:
        return "https://somedir-py.somedir.test.iterate.no";
      case Environment.Snap0:
        return "https://snap0.somedir-py.somedir.iterate.iterapp.no";
      case Environment.Snap1:
        return "https://snap1.somedir-py.somedir.iterate.iterapp.no";
      case Environment.Production:
        return "https://py.echotheapp.com";
      default:
        return;
    }
}
