import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import Icon from "../../utils/Icon";
import { ChamberType, NestedChamberType } from "../../utils/interfaces";
import AddNestedChamberComponent from "./AddNestedChamberComponent";
import { Urls } from "./Urls";
import { Link as rLink } from "react-router-dom";

const Links = ({ slug }: { slug: string }) => {
  const [nestedChambers, setNestedChambers] = useState<ChamberType[]>([]);

  const addNestedChamberMutation = useMutation(
    (data: NestedChamberType) => {
      return axios.post(Urls.api.addNewLinkedChamber(slug), data);
    },
    {
      onError: (e: any) => console.log("chamber link error: ", e),
      onSuccess: (response: any) => {
        setNestedChambers((prev) => [
          ...prev,
          { slug: response.data.slug, headline: response.data.headline },
        ]);
      },
    }
  );

  const removeNestedChamberMutation = useMutation(
    (data: NestedChamberType) => {
      return axios.post(Urls.api.removeNestedChamber(slug), data);
    },
    {
      onSuccess: (response: any) => {
        console.log("chamber link deleted");
      },
    }
  );

  const { refetch } = useQuery("getLinkedChambers", async () => {
    const linkedChambers = await (
      await fetch(Urls.api.getLinkedChambers(slug))
    ).json();
    setNestedChambers(linkedChambers);
  });

  const addNestedChamber = (chamber: NestedChamberType) => {
    if (chamber.nested_slug !== slug) {
      addNestedChamberMutation.mutate(chamber);
    }
  };

  const removeNestedChamber = (chamber: NestedChamberType) => {
    removeNestedChamberMutation.mutate(chamber);
    setNestedChambers((prev) => [
      ...prev.filter((chamb) => chamb.slug !== chamber.nested_slug),
    ]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    refetch();
    return () => {
      setNestedChambers([]);
    };
  }, [slug, refetch]);

  const { data } = useQuery("popularChambers", async () => {
    if (slug === "homepage") {
      const popularChambers = await (
        await fetch(Urls.api.getPopularChambers())
      ).json();
      return popularChambers;
    }
  });

  let popularChambers: ChamberType[] = [];
  if (data !== undefined && data !== null && !data["error"]) {
    const chambers: ChamberType[] = data.map(
      (e: { nested_slug: any; headline: any }) => ({
        slug: e.nested_slug,
        headline: e.headline,
      })
    );
    popularChambers = chambers;
  }

  if (slug === "homepage") {
    return (
      <Wrapper>
        <LinkHeader>Popular Chambers</LinkHeader>
        <List>
          {popularChambers.map((chamber: ChamberType) => {
            return <Link key={chamber.slug} chamber={chamber} />;
          })}
        </List>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <LinkHeader>Chamber Links</LinkHeader>
        <AddNestedChamberComponent onSubmit={addNestedChamber} />
        <List>
          {nestedChambers.map((chamber: ChamberType) => {
            return (
              <Link
                key={chamber.slug}
                chamber={chamber}
                deleteLink={removeNestedChamber}
              />
            );
          })}
        </List>
      </Wrapper>
    );
  }
};

export default Links;

const Wrapper = styled.div`
  border-radius: 6px;
  border: 1px ${colors.lightGreyIsh} solid;
  margin-top: 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  color: ${colors.white};
`;

const LinkHeader = styled.div`
  font-weight: bold;
`;

const List = styled.ul`
  padding: 0;
  padding-left: 1em;
`;

const Link = ({
  chamber,
  deleteLink,
}: {
  chamber: ChamberType;
  deleteLink?: (chamber: NestedChamberType) => void;
}) => {
  return (
    <LinkWrapper>
      <LinkContainer to={`/chamber/${chamber.slug}`}>
        <div>
          <Icon name={"link"} />
        </div>
        <div>{chamber.headline || chamber.slug}</div>
      </LinkContainer>
      {deleteLink && (
        <TrashButton
          onClick={() => {
            deleteLink({ nested_slug: chamber.slug });
          }}
        >
          <Icon name={"trash"} />
        </TrashButton>
      )}
    </LinkWrapper>
  );
};

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > * + * {
    display: none;
  }
  :hover {
    > * + * {
      display: inline;
    }
  }
`;

const LinkContainer = styled(rLink)`
  display: flex;
  justify-content: flex-start;
  color: ${colors.white};
  text-decoration: none;
  text-align: left;
  width: 100%;

  :hover {
    color: ${colors.contrastColor};
    text-decoration: underline;
  }
  > * + * {
    margin-left: 8px;
    margin-right: auto;
  }
`;

const TrashButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${colors.contrastColor};
  padding: 0;
  margin: 0;
`;
