import styled from "styled-components";
import { colors } from "./colors";

export const mobileWidth = "1000px";

export const StyledInput = styled.input`
  background-color: transparent;
  border: 1px solid ${colors.contrastColor};
  border-radius: 2em;
  padding: 0.8em;
  color: ${colors.contrastColor};
  box-sizing: border-box;
  width: 100%;
  margin-top: 1em;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.contrastColor};
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: ${colors.contrastColor};
    opacity: 1;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (max-width: ${mobileWidth}) {
   font-size: 1em;
   margin-bottom: 1em
  }
`;

export const SideButton = styled.button`
  background-color: ${colors.contrastColor2};
  border: none;
  border-radius: 1em;
  margin-left: 1em;
  margin-right: 1em;
  height: 1em;
  width: 1em;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover{
    background-color: ${colors.contrastColor};
  }

  @media (max-width: ${mobileWidth}) {
    box-sizing: border-box;
    height: 2em;
    width: 2em;
    margin-left: 4px;
  }
`;

export const BoldtText = styled.p`
  color: ${colors.white};
  font-weight: bold;
  margin: 0;
  flex-wrap: wrap;
  cursor: pointer;
  :hover {
    color: ${colors.contrastColor};
    text-decoration: underline;
  }
`;

export const RegText = styled.p`
  color: ${colors.white};
  margin: 0;
`;

export const GreenText = styled.p`
  margin: 0;
  color: ${colors.contrastColor};
  font-weight: bold;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;