import { getPyPath, getWsPath } from "../../utils/env";

const wsPath = getWsPath();
const pyPath = getPyPath();
export const Urls = {
  api: {
    getChamber: (slug) => `/api/c/${slug}`,
    postHeadline: (slug) => `/api/c/${slug}/headline`,
    searchPeople: (needle) => `/api/search?name=${needle}`,
    upfork: (slug) => `/api/c/${slug}/upfork`,
    downfork: (slug) => `/api/c/${slug}/downfork`,
    singleton: () => `/api/c/singleton`,
    getPersonInfo: (personid) => `/api/people/${personid}/info`,
    addLike: () => `/api/like`,
    chat: (slug) => `${wsPath}/api/c/${slug}/ws`,
    homeFeedPosts: (limit, offset, popular) =>  `/api/home-feed?limit=${limit}&offset=${offset}&pop=${popular}`,
    getLinkedChambers:(slug) => `/api/c/${slug}/nested-chambers`,
    addNewLinkedChamber:(slug) => `/api/c/${slug}/add-nested-chamber`,
    getPopularChambers: () => `/api/get-most-popular-nested-slugs`,
    removeNestedChamber:(slug) => `/api/c/${slug}/remove-nested-chamber`,
    popularChamberPosts: (slug,limit, offset) =>  `/api/c/${slug}/popular-posts?limit=${limit}&offset=${offset}`,
    getNextPosts: (personId, offset) => `/api/people/${personId}/next-posts?offset=${offset}`,
    getNextPostsForChamber: (slug, offset) => `/api/c/${slug}/next-posts?offset=${offset}`
  },
  www: {
    getChamber: (slug) => `/chamber/${slug}`,
    chamberIndex: () => `/`,
  },
  py: {
    suggestion: () => `${pyPath}/suggestion`
  }
};
