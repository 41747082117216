type Props = {
  className?: string;
  width?: number;
  height?: number;
};

const MinusIcon = ({ className, width, height }: Props): JSX.Element => {
  return (
    <svg
      width={width ? width.toString() : "20"}
      height={height ? height.toString() : "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16,10c0,0.553-0.048,1-0.601,1H4.601C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H15.4C15.952,9,16,9.447,16,10z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MinusIcon;
