import styled from "styled-components";
import { colors } from "../../utils/colors";

const EmojiButton = ({
  value,
  emoji,
  onClick,
  selected = false,
}: {
  value: string;
  emoji: string;
  onClick: () => void;
  selected?: boolean;
}) => {
  return (
    <Container selected={selected} onClick={onClick}>
      <Text>{emoji}</Text>
      <Text>{value}</Text>
    </Container>
  );
};

export default EmojiButton;

const Container = styled.button<{ selected: boolean }>`
  min-width: 50px;
  height: 30px;
  border-radius: 48px;
  background-color: ${(props) =>
    props.selected ? colors.white : "transparent"};
  border: 1px solid ${colors.white};
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  padding: 8px 5px;
  color: ${(props) => (props.selected ? colors.backgroundColor : colors.white)};
  cursor: pointer;

  :hover {
    border: 1px solid ${colors.lightGreyIsh};
  }
  :active {
    border: 1px solid ${colors.contrastColor};
    background-color: ${colors.greyIsh};
  }
`;

const Text = styled.div`
  line-height: 24px;
  margin-right: 4px;
`;
