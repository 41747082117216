import { ChangeEvent } from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";

const ChatInput = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <CustomInput
      value={value}
      onChange={(event) => onChange(event)}
      placeholder={"Your message.."}
      maxLength={128}
    />
  );
};

export default ChatInput;

const CustomInput = styled.input`
  all: unset;
  font-family: inherit;
  color: ${colors.white};
  width: 100%;
  margin-right: 1em;
`;
