import wordpair from "random-word-pairs";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import { Urls } from "../chamber/Urls";
import TextButton from "../util/TextButton";
import ChatInput from "./ChatInput";
import { Message } from "../../utils/interfaces";
import MessageFeed from "./MessageFeed";

const Chat = ({ headline, slug }: { headline: string; slug: string }) => {
  const [chatMessage, setChatMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [userName, setUsername] = useState<string>("");
  const [socketOpen, setSocketOpen] = useState<boolean>(false);
  const ws = useRef<WebSocket>();

  const sendMessage = () => {
    if (chatMessage.trim().length > 0) {
      if (socketOpen) {
        let msg = JSON.stringify({
          author: userName,
          message: chatMessage,
        });
        ws.current?.send(msg);
      }
      setChatMessage("");
    }
  };

  useEffect(() => {
    if (userName === "") {
      setUsername(wordpair({ delim: " " }));
    }
  }, [userName, setUsername]);

  useEffect(() => {
    ws.current = new WebSocket(Urls.api.chat(slug));
    ws.current.onopen = () => {
      setSocketOpen(true);
      setMessages([]);
    };

    const wsCurrent = ws.current;

    return () => {
      wsCurrent.close();
    };
  }, [slug]);

  useEffect(() => {
    if (ws.current) {
      ws.current.onmessage = (event: MessageEvent) => {
        let newMessage: Message = JSON.parse(event.data);
        let arrayMessage: Message[] = JSON.parse(event.data);

        if (arrayMessage.length > 0 && messages.length === 0) {
          setMessages(arrayMessage);
        } else {
          if (messages.length > 10) {
            let slicedMessages = messages.slice(-10);
            setMessages([...slicedMessages, newMessage]);
          } else {
            setMessages((prev) => [...prev, newMessage]);
          }
        }
      };
    }
  }, [messages]);

  return (
    <Wrapper>
      <HeadLineContainer>
        <ChatHeader>{"Chat"}</ChatHeader>
        <Headline>{headline}</Headline>
      </HeadLineContainer>
      <MessagesContainer>
        <MessageFeed messages={messages} userId={userName} />
      </MessagesContainer>
      <MessageForm onSubmit={(e) => e.preventDefault()}>
        <ChatInput
          value={chatMessage}
          onChange={(event) => {
            setChatMessage(event.target.value);
          }}
        />
        <TextButton
          text={"Send"}
          onClick={() => {
            sendMessage();
          }}
        />
      </MessageForm>
    </Wrapper>
  );
};

export default Chat;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: inherit;
  font-family: inherit;
`;

const MessageForm = styled.form`
  border-top: 1px solid #44514f;
  height: 80;
  padding: 1em 2em;
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MessagesContainer = styled.div`
  padding: 1em;
  height: calc(80vh - 140px);
  box-sizing: inherit;
`;

const HeadLineContainer = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #44514f;
  > * {
    padding-left: 1em;
  }
`;

const ChatHeader = styled.p`
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  color: ${colors.lightGreyIsh};
`;

const Headline = styled.p`
  font-size: 18px;
  line-height: 21px;
  color: ${colors.lightGreyIsh};
`;
