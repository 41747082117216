import styled from "styled-components";
import { Message } from "../../utils/interfaces";
import MessageItem from "./MessageItem";

const MessageFeed = ({
  messages,
  userId,
}: {
  messages: Message[];
  userId: string;
}) => {
  return (
    <ListContainer>
      {messages.map((message, i) => {
        if (message.author) {
          return (
            <MessageItem
              key={message.id + i.toString()}
              message={message}
              isOwnMessage={userId === message.author}
            />
          );
        } else {
          return <div key={i}></div>;
        }
      })}
    </ListContainer>
  );
};

export default MessageFeed;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 1em;
  height: 100%;
  overflow: hidden;
`;
