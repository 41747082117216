import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import "./Chamber.css";
import {
  SideButton,
  StyledInput,
  GreenText,
  RegText,
  BoldtText,
  Column,
  RowSpaceBetween,
} from "../../utils/styles";
import styled from "styled-components";
import { useMutation } from "react-query";
import { Urls } from "../chamber/Urls";
import axios from "axios";
import { useNavigate } from "react-router";
import Icon from "../../utils/Icon";

const SearchResult = ({ result, addSub, isSubbed, needle }) => {
  const { name, personId, aliases, dob } = result;
  const hasAlias = aliases[0] !== undefined;
  const aliasStartsWithNeedle =
    hasAlias &&
    aliases[0].toString().toLowerCase().startsWith(needle.toString());
  const invalidDate = dob === undefined || dob.toString() === "00.00.0000";
  const navigate = useNavigate();

  const getPersonChamberMutation = useMutation(
    (personId) => axios.post(Urls.api.singleton(), { personid: personId }),
    {
      onSuccess: ({ data }) => {
        const url = Urls.www.getChamber(data.slug);
        navigate(url);
      },
    }
  );

  if (isSubbed(personId)) {
    return <></>;
  } else {
    if (aliasStartsWithNeedle) {
      return (
        <ResultRow>
          <SideButton onClick={() => addSub(personId)}>
            <Icon name="plus" />
          </SideButton>
          <GreenText onClick={() => getPersonChamberMutation.mutate(personId)}>
            {aliases[0]}
          </GreenText>
        </ResultRow>
      );
    } else {
      return (
        <ResultRow>
          <SideButton onClick={() => addSub(personId)}>
            <Icon name="plus" />
          </SideButton>
          <Column>
            <RowSpaceBetween>
              <BoldtText
                onClick={() => getPersonChamberMutation.mutate(personId)}
              >
                {name}
              </BoldtText>
              {!invalidDate ? <RegText>{dob}</RegText> : <></>}
            </RowSpaceBetween>
            {hasAlias ? (
              <GreenText
                onClick={() => getPersonChamberMutation.mutate(personId)}
              >
                {aliases[0]}
              </GreenText>
            ) : (
              <></>
            )}
          </Column>
        </ResultRow>
      );
    }
  }
};

export const PeopleSearch = ({ addSub, subs }) => {
  const [needle, setNeedle] = useState();
  const [results, setResults] = useState([]);

  const isSubbed = (personId) => subs && subs.includes(personId);

  const { data, loading } = useQuery(
    ["search", needle],
    () =>
      fetch(Urls.api.searchPeople(needle)).then((res) => {
        return res.json();
      }),
    { enabled: !!(needle && needle.length > 0) }
  );

  useEffect(() => {
    if (!loading && data) {
      setResults(data);
    }
  }, [loading, data]);

  return (
    <>
      <StyledInput
        placeholder="Search for someone"
        onChange={(e) => setNeedle(e.target.value)}
      />
      {needle && needle.length > 1 ? (
        <List>
          {results.map((result) => (
            <SearchResult
              key={result.personId}
              result={result}
              addSub={addSub}
              isSubbed={isSubbed}
              needle={needle}
            />
          ))}
        </List>
      ) : (
        <></>
      )}
    </>
  );
};

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  > * + * {
    margin-top: 8px;
  }
`;

const ResultRow = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 4px;
`;
