import useAutoResize from "./useAutoResize";

const YoutubeEmbed = ({ url }: { url: string }) => {
  const [width, height] = useAutoResize();

  return (
    <iframe
      src={url}
      width={width}
      height={height}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={true}
      title={"youtubeembed" + url}
    ></iframe>
  );
};

export default YoutubeEmbed;
