import React from "react";

const RedditEmbed = ({ html }) => {
  
  return (
    <div className="reditEmbed" dangerouslySetInnerHTML={{__html: html}}></div>
  );
};

export default RedditEmbed;
