import React from "react";
import "./App.css";
import { Chamber } from "./components/chamber/Chamber";
import { FrontPage } from "./components/chamber/Frontpage";
import Header from "./components/header/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AddPerson from "./components/add-person/AddPerson";
import ChamberNotFound from "./components/util/ChamberNotFound";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    />
    <Router>
      <div className="app">
        <Header />
        <div style={{ marginTop: "4em" }}>
          <Routes>
            <Route path="/chamber/:slug" element={<Chamber />} />
            <Route path="/" element={<FrontPage />} />
            <Route path="/add-person" element={<AddPerson />} />
            <Route path="/chamber-not-found" element={<ChamberNotFound />} />
          </Routes>
        </div>
      </div>
    </Router>
  </QueryClientProvider>
);

export default App;
