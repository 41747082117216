
export const colors = {
    backgroundColor : "#242E23",
    white: "#E4E9E4",
    greyIsh:  "#44514F",
    lightGreyIsh: "#899389",
    contrastColor: "#84EA7B",
    buttonGrey: "#455144",
    contrastColor2: "#B0CAF1",
    error: "#FFcccc"
}
