import styled from "styled-components";
import { colors } from "../../utils/colors";
import { Message } from "../../utils/interfaces";
import { mobileWidth } from "../../utils/styles";

const MessageItem = ({
  message,
  isOwnMessage,
}: {
  message: Message;
  isOwnMessage: boolean;
}) => {
  return (
    <Container>
      <TopRow isOwnMessage={false}>
        <AuthorText isOwnMessage={isOwnMessage}>{message.author}</AuthorText>
        <TimeStampText>
          {message.epoch
            ? new Date(message.epoch * 1000).toLocaleString(navigator.language)
            : "just now"}
        </TimeStampText>
      </TopRow>
      <MessageText isOwnMessage={false}>{message.message}</MessageText>
    </Container>
  );
};

export default MessageItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  min-height: 12%;

  > * + * {
    margin-top: 16px;
  }

  @media (max-width: ${mobileWidth}) {
    min-height: 64px;
  }
`;

const TopRow = styled.div<{ isOwnMessage: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isOwnMessage ? "row-reverse" : "row")};
  justify-content: space-between;
  height: 20px;
`;

const AuthorText = styled.p<{ isOwnMessage: boolean }>`
  all: unset;
  color: ${(props) =>
    props.isOwnMessage ? colors.contrastColor : colors.contrastColor2};
  font-weight: bold;
  font-size: 16px;
`;

const TimeStampText = styled.p`
  all: unset;
  color: ${colors.lightGreyIsh};
  font-size: 12px;
  line-height: 16px;
`;

const MessageText = styled.p<{ isOwnMessage: boolean }>`
  all: unset;
  color: ${colors.white};
  text-align: ${(props) => (props.isOwnMessage ? "right" : "left")};
  font-size: 16px;
  height: 60%;
`;
