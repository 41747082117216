import { useNavigate } from "react-router-dom";
import { colors } from "../../utils/colors";

export const Logo = () => {
  const navigate = useNavigate();

  const logoPressed = () => {
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    } else {
      navigate("/");
    }
  };

  return (
    <div
      onClick={() => logoPressed()}
      style={{
        fontWeight: "bold",
        color: colors.white,
        fontSize: "29px",
        lineHeight: "33px",
        textDecoration: "none",
        cursor: "pointer",
      }}
    >
      ECHO
    </div>
  );
};

export default Logo;
