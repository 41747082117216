import { mobileWidth } from "../../utils/styles";
import styled from "styled-components";

const ChamberNotFound = () => {
  return (
    <Wrapper>
      <Headline1>Something went wrong when fetching the chamber!</Headline1>
      <Headline2>
        Try creating the chamber again by going to the frontpage.
      </Headline2>
    </Wrapper>
  );
};

export default ChamberNotFound;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 6em;
  @media (max-width: ${mobileWidth}) {
    padding: 8px;
  }
`;

const Headline1 = styled.h1`
  text-align: center;
  @media (max-width: ${mobileWidth}) {
    font-size: 1.4em;
  }
`;

const Headline2 = styled.h2`
  line-height: 42px;
  text-align: center;
  @media (max-width: ${mobileWidth}) {
    font-size: 1.2em;
  }
`;
