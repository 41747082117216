import { useState, useEffect } from "react";

const useAutoResize = () => {
  

  const [width, setWidth] = useState(520);

  useEffect(() => {
    setWidth(window.innerWidth);
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return [calcWidth(width), calcHeight(width)];
};

const calcWidth = (width: number): number => {
  // Width here tries to follow the width of the container. Prone to breaking.

  if (width > 767) {
    return 520;
  } else if (width > 575) {
    return 420;
  } else {
    return width - 16 ;
  }
};
const calcHeight = (width: number): number => calcWidth(width) * 0.5625;

export default useAutoResize;
