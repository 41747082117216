import { ChangeEvent } from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import { mobileWidth } from "../../utils/styles";

const PersonInput = ({
  title,
  placeholder,
  value,
  onChange,
}: {
  title: string;
  placeholder: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Row>
      <Title>{title}</Title>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event)}
      />
    </Row>
  );
};

export default PersonInput;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
  @media (max-width: ${mobileWidth}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 70%;
  border: solid 1px ${colors.contrastColor};
  background-color: transparent;
  color: ${colors.white};
  padding: 8px 16px;
  border-radius: 48px;

  :focus {
    outline: none;
  }

  @media (max-width: ${mobileWidth}) {
    width: 100%;
  }
`;

const Title = styled.div`
  @media (max-width: ${mobileWidth}) {
    margin-left: 16px;
    margin-bottom: 8px;
  }
`;
