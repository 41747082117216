import { useEffect, useState } from "react";
import styled from "styled-components";
import Post from "../post/Post";
import InfiniteScroll from "react-infinite-scroll-component";
import { colors } from "../../utils/colors";
import { Urls } from "./Urls";
import { PostType, resToPosts } from "../../utils/postType";
import { useMutation } from "react-query";
import axios from "axios";
import { FilterMode } from "./Frontpage";
import ReactLoading from "react-loading";

const InfiniteFeed = ({
  filterMode,
  slug,
}: {
  filterMode: FilterMode;
  slug?: string;
}) => {
  const [posts, setPosts] = useState<PostType[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const limit = 5;

  const fetchMorePosts = async () => {
    fetchPosts(limit, offset).then((newposts) => {
      if (newposts.length > 0) {
        setOffset(offset + newposts.length);
        setPosts((prev) => [...prev, ...newposts]);
      } else {
        setHasMore(false);
      }
    });
  };

  const fetchPosts = async (lim: number, off: number) => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (slug) {
      const response = fetch(Urls.api.popularChamberPosts(slug, lim, off), {
        method: "get",
        signal,
      });

      const data = await (await response).json();
      if (signal.aborted) {
        return resToPosts([]);
      }
      return resToPosts(data);
    } else {
      const response = fetch(
        Urls.api.homeFeedPosts(lim, off, filterMode === FilterMode.POPULAR),
        { method: "get", signal }
      );
      const data = await (await response).json();
      if (signal.aborted) {
        return resToPosts([]);
      }
      return resToPosts(data);
    }
  };

  useEffect(() => {
    setPosts([]);
    setHasMore(true);
    setOffset(0);
    fetchPosts(limit, 0).then((newposts) => {
      setOffset(newposts.length);
      setPosts(newposts);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMode]);

  useEffect(() => {
    fetchPosts(limit, offset).then((newposts) => {
      setOffset(newposts.length);
      setPosts(newposts);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPosts([]);
  }, [slug, filterMode]);

  const addLikeMutation = useMutation(
    (data: any) => {
      return axios.post(Urls.api.addLike(), data);
    },
    { onError: (e) => console.log(e) }
  );

  const addLike = (post: PostType, likes: number) => {
    let data;
    data = {
      cursor: post.cursor,
      media: post.media,
      mediaId: post.id ? post.id : "",
      personId: post.personId,
      personName: post.personName,
      time: post.time,
      url: post.url,
      slug: slug ? slug : "frontpage",
      aliases: post.aliases ? post.aliases : [],
    };
    addLikeMutation.mutate(data);
  };

  return (
    <Wrapper>
      <InfiniteScroll
        next={fetchMorePosts}
        dataLength={posts.length}
        hasMore={hasMore}
        loader={
          <Loading>
            <ReactLoading
              type={"spokes"}
              color={colors.contrastColor}
              width={40}
            />
          </Loading>
        }
      >
        {posts.map((post, i) => {
          return <Post key={i} post={post} addLike={addLike} />;
        })}
      </InfiniteScroll>
    </Wrapper>
  );
};

export default InfiniteFeed;

const Wrapper = styled.div`
  box-sizing: inherit;
  font-family: inherit;
  width: 100%;
`;

const Loading = styled.h4`
  color: ${colors.contrastColor};
  width: 100%;
  display: flex;
  justify-content: center;
`;
