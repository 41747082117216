import axios from "axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import styled from "styled-components";
import "./Chamber.css";
import InfiniteFeed from "./InifiniteFeed";
import { PeopleSearch } from "./PeopleSearch";
import { Urls } from "./Urls";
import EmojiButton from "../util/EmojiButton";
import { useState } from "react";
import Links from "./Links";
import { mobileWidth } from "../../utils/styles";
import { colors } from "../../utils/colors";

export const enum FilterMode {
  POPULAR,
  NEW,
}

export const FrontPage = () => {
  const navigate = useNavigate();

  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.POPULAR);

  const getSingletonForMutation = useMutation(
    (personId: string) =>
      axios.post(Urls.api.singleton(), { personid: personId }),
    {
      onSuccess: ({ data }) => {
        const url = Urls.www.getChamber(data.slug);
        navigate(url);
      },
    }
  );

  const getSingletonFor = (personId: string) => {
    getSingletonForMutation.mutate(personId);
  };

  return (
    <Wrapper>
      <Container>
        <LeftContainer>
          <Headline1>The Front Page of Social Media</Headline1>
          <PeopleSearch subs={[]} addSub={getSingletonFor} />
          <Links slug="homepage" />
          <ButtonContainer>
            <p
              style={{
                padding: "0",
                margin: "0 auto 0 0 ",
                fontSize: "14px",
              }}
            >
              download app:{" "}
            </p>
            <Storebutton
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.echo.theapp",
                  "_blank"
                );
              }}
            >
              <StoreIcon src="/images/google-logo.png" alt="Google Logo" />
              <ButtonText>Google Play</ButtonText>
            </Storebutton>
            <Storebutton
              onClick={() => {
                window.open(
                  "https://apps.apple.com/us/app/echo-the-app/id1606372851",
                  "_blank"
                );
              }}
            >
              <StoreIcon src="/images/apple-logo.png" alt="Apple Logo" />
              <ButtonText>App Store</ButtonText>
            </Storebutton>
          </ButtonContainer>
        </LeftContainer>
        <RightContainer>
          <Headline2>
            {filterMode === FilterMode.POPULAR
              ? "Popular posts via ECHO"
              : "New posts via ECHO"}
          </Headline2>
          <ButtonWrapper>
            <EmojiButton
              value={"Popular"}
              emoji={"🔥"}
              onClick={() => {
                setFilterMode(FilterMode.POPULAR);
              }}
              selected={filterMode === FilterMode.POPULAR}
            />
            <EmojiButton
              value={"New"}
              emoji={"🕒"}
              onClick={() => {
                setFilterMode(FilterMode.NEW);
              }}
              selected={filterMode === FilterMode.NEW}
            />
          </ButtonWrapper>
          <InfiniteFeed filterMode={filterMode} />
        </RightContainer>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media (max-width: ${mobileWidth}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const LeftContainer = styled.div`
  width: 40%;
  @media (max-width: ${mobileWidth}) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  padding-left: 2em;
  width: auto;
  min-width: 600px;
  @media (max-width: ${mobileWidth}) {
    min-width: 0;
    width: 100%;
    padding-left: 0;
  }
`;

const Headline1 = styled.h1`
  @media (max-width: ${mobileWidth}) {
    font-size: 1.4em;
  }
`;
const Headline2 = styled.h2`
  line-height: 42px;
  @media (max-width: ${mobileWidth}) {
    font-size: 1.2em;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  height: 64px;
  > * + * {
    margin-left: 8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
  align-items: center;
`;

const Storebutton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100px;
  margin-left: 8px;
  border: none;
  background-color: ${colors.white};
  color: ${colors.backgroundColor};
  padding: 12px;
  :hover {
    background-color: ${colors.buttonGrey};
    color: ${colors.white};
  }
`;

const ButtonText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const StoreIcon = styled.img`
  height: 16px;
  margin-right: 8px;
`;
