import styled from "styled-components";
import { colors } from "../../utils/colors";

const TextButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => {
  return (
    <Button type={"submit"} onClick={onClick}>
      <p>{text}</p>
    </Button>
  );
};

export default TextButton;

const Button = styled.button`
  box-sizing: border-box;
  min-width: 75px;
  height: 40px;
  border-radius: 48px;
  background-color: ${colors.buttonGrey};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding: 8px 5px;
  color: ${colors.lightGreyIsh};
  cursor: pointer;
  font-family: inherit;

  :hover {
    border: 1px solid ${colors.lightGreyIsh};
    color: ${colors.white};
  }
  :active {
    border: 1px solid ${colors.contrastColor};
    background-color: ${colors.contrastColor};
    color: ${colors.buttonGrey};
  }
`;
