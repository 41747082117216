import { useState } from "react";
import { useQuery } from "react-query";
import { BoldtText, Column, GreenText, SideButton } from "../../utils/styles";
import { useMutation } from "react-query";
import { Urls } from "./Urls";
import axios from "axios";
import { useNavigate } from "react-router";
import { PersonType } from "../../utils/interfaces";
import styled from "styled-components";
import { colors } from "../../utils/colors";
import Icon from "../../utils/Icon";

const PersonItem = ({
  personId,
  removeSub,
}: {
  personId: string;
  removeSub: (personId: string) => void;
}) => {
  const [person, setPerson] = useState<PersonType>();
  const navigate = useNavigate();

  const getPersonChamberMutation = useMutation(
    (personId: string) =>
      axios.post(Urls.api.singleton(), { personid: personId }),
    {
      onSuccess: ({ data }) => {
        const url = Urls.www.getChamber(data.slug);
        navigate(url);
      },
    }
  );

  useQuery(
    ["person", personId],
    () =>
      fetch(Urls.api.getPersonInfo(personId)).then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error("" + res.status);
        }
      }),
    {
      onSuccess: (data: PersonType) => {
        setPerson(data);
      },
      onError: (err: Error) => {
        navigate("/chamber-not-found");
      },
    }
  );

  if (person) {
    return (
      <Container key={personId}>
        <SideButton onClick={() => removeSub(personId)}>
          <Icon name="minus" />
        </SideButton>{" "}
        <PersonText
          onClick={() => getPersonChamberMutation.mutate(person.personId)}
        >
          {person.aliases[0] !== undefined ? (
            <Column>
              <BoldtText>{person.name}</BoldtText>
              <GreenText>{person.aliases[0]}</GreenText>
            </Column>
          ) : (
            <BoldtText>{person.name}</BoldtText>
          )}
        </PersonText>
      </Container>
    );
  } else {
    return <div>loading...</div>;
  }
};

const PersonText = styled.span`
  cursor: pointer;
  :hover {
    color: ${colors.contrastColor};
    text-decoration: underline;
  }
`;

const Container = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const PeopleList = ({
  people,
  removeSub,
}: {
  people: string[];
  removeSub: (person: string) => void;
}) => {
  if (people) {
    return (
      <>
        <h3>People</h3>
        <List id="people-list">
          {people.map((person) => (
            <PersonItem key={person} personId={person} removeSub={removeSub} />
          ))}
        </List>
      </>
    );
  } else {
    return (
      <>
        <h3>People</h3>
        No people in this chamber. Add someone!
      </>
    );
  }
};

const List = styled.ul`
  > * + * {
    margin-top: 8px;
  }
`;
